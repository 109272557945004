<template>
  <TravelAlertsPage
    :headline="headline"
    :introduction="introduction"
    :travelAlertsPanels="panels"
    :page="page"
    :alertsList="alertsList"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { InitPageParams } from "lib/utils/initPage";
import { getBaseLink } from "~~/lib/utils/link/getBaseLink";
import TravelAlertsPage from "~/components/TravelAlerts/TravelAlertsPage.vue";
import { TravelAlertsPage as TravelAlertsPageResponse } from "~~/lib/types/Contentstack/ContentTypes/TravelAlertsPage";
import { initPage } from "~~/lib/utils/initPage";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Page, logTags.Page.TravelAlerts],
});

export default Vue.extend({
  name: "TravelAlerts",
  components: {
    TravelAlertsPage,
  },
  async asyncData({
    store,
    i18n,
    route,
    params,
    $config,
  }): Promise<TravelAlertsPageResponse | undefined> {
    try {
      const requestParams: InitPageParams = {
        locale_iso: i18n.localeProperties.iso,
        locale_code: i18n.locale,
        locale_domain: $config.public.baseUrl,
        path: route.path,
        slug: getBaseLink(route.path),
        currency_code:
          params.currencyCode || i18n.localeProperties.currencyCode,
        current_path: getBaseLink(route.path),
        page_type: "content",
      };

      const pageContent: TravelAlertsPageResponse = await $fetch(
        "/api/nuxt/travel-alerts/page",
        {
          params: requestParams,
        }
      );

      await Promise.all(initPage(store, requestParams));

      return pageContent;
    } catch (error) {
      logger.error("Error occurred in asyncData in travel-alerts.vue", error);
    }
  },
  data() {
    return {
      headline: {},
      introduction: {},
      panels: {},
      page: {},
      alertsList: [],
    };
  },
});
</script>
