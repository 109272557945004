<template>
  <div>
    <template v-for="(alert, index) in alertsList">
      <Accordion
        v-if="alert.updatedEntry.length"
        :key="index"
        :description="alert.displayName"
        data-cy="alerts-list"
      >
        <div slot="content">
          <AccordionItem>
            <h3
              slot="title"
              class="headline--5 u-margin-top--0 u-margin-bottom--0"
            >
              {{
                `${alert.displayName}, Updated ${
                  alert.updatedEntry.at(0)?.date
                }`
              }}
            </h3>
            <div slot="content">
              <JsonRichText
                v-if="alert.updatedEntry[0].description"
                :json="alert.updatedEntry[0].description"
              />
            </div>
          </AccordionItem>
        </div>
      </Accordion>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Accordion from "atlas/src/components/Accordion/Accordion.vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";
import JsonRichText from "../JsonRichText/JsonRichText.vue";
import { AlertsProps } from "./Props";

export default Vue.extend({
  name: "AlertsList",
  components: {
    Accordion,
    AccordionItem,
    JsonRichText,
  },
  props: {
    alertsList: {
      type: Array as PropType<AlertsProps["alertsList"]>,
      required: false,
      default: undefined,
    },
  },
});
</script>
