<template>
  <Page v-bind="page">
    <div class="u-margin-top--2">
      <ErrorBoundary v-if="headline">
        <PageHeadline v-bind="headline" data-cy="travel-alerts-headline" />
      </ErrorBoundary>
      <div
        class="
          l-container l-container--small
          u-margin-top--4
          md:u-margin-top--6
        "
      >
        <ErrorBoundary v-if="introduction">
          <Introduction
            class="u-margin-bottom--3 sm:u-margin-bottom--6"
            :tagline="introduction?.tagline"
            :longDescription="introduction?.longDescription"
            data-cy="travel-alerts-introduction"
          />
        </ErrorBoundary>
      </div>
      <ErrorBoundary
        v-if="alertsList?.length"
        data-cy="travel-alerts-list"
        class="l-container"
      >
        <h2 data-cy="travel-alerts-heading">Alerts</h2>
        <AlertsList :alertsList="alertsList" />
      </ErrorBoundary>

      <ErrorBoundary v-if="hasPanels">
        <Panels
          v-bind="travelAlertsPanels"
          class="l-container u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
    </div>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import PageHeadline from "../PageHeadline/PageHeadline.vue";
import Introduction from "../Introduction/Introduction.vue";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary.vue";
import Page from "../Page/Page.vue";
import AlertsList from "../AlertsList/AlertsList.vue";
import { TravelAlertsPageProps } from "./Props";
import Panels from "~/components/Panels/Panels.vue";

export default Vue.extend({
  name: "TravelAlertsPage",
  components: {
    Page,
    PageHeadline,
    Introduction,
    Panels,
    ErrorBoundary,
    AlertsList,
  },
  props: {
    page: {
      type: Object as PropType<TravelAlertsPageProps["page"]>,
      required: true,
    },
    headline: {
      type: Object as PropType<TravelAlertsPageProps["headline"]>,
      required: false,
      default: undefined,
    },
    introduction: {
      type: Object as PropType<TravelAlertsPageProps["introduction"]>,
      required: false,
      default: undefined,
    },
    travelAlertsPanels: {
      type: Object as PropType<TravelAlertsPageProps["panels"]>,
      required: true,
    },
    alertsList: {
      type: Array as PropType<TravelAlertsPageProps["alertsList"]>,
      required: false,
      default: undefined,
    },
  },
  computed: {
    hasPanels() {
      return this.travelAlertsPanels && this.travelAlertsPanels.panels.length;
    },
  },
});
</script>
